import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import LoaderSymbol from '../components/LoaderSymbol';
import { history } from '../routers/AppRouter';
import store from '../app/store';

export const PrivateRoute = ({
    isAuthenticated,
    component: Component,
    ...rest
}) => {
    useEffect((isAuthenticated) => {
        const timer = setTimeout(() => {
            if (!store.getState().auth.user) {
                history.push("/");
            }
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} component={(props) => (
            isAuthenticated ? (
                <Component {...props} />
            ) : (
                <LoaderSymbol />
            )
        )}/>
    )
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid 
});

export default connect(mapStateToProps)(PrivateRoute);