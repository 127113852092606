import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loader from 'react-loader-spinner';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoaderSymbol = () => (
    <Container className="mb-4 mt-4">
        <Row className="justify-content-center">
            <Loader
                type="Circles"
                color="#00BFFF"/>   
        </Row>
    </Container>
);

export default LoaderSymbol;