import md5 from 'md5';

const gravatarUrl = email => {
    if (!email) {
        return "https://www.gravatar.com/avatar/?s=30&d=mp";
    }
    return "https://www.gravatar.com/avatar/" + md5(email.trim()) + "?s=30&d=mp";
};

export default (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                uid: action.user.uid,
                user: action.user,
                avatarUrl: action.user.photoURL ? action.user.photoURL : gravatarUrl(action.user.email)
            }
        case 'LOGOUT':
            return {}
        default:
            return state;
    }
}