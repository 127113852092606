const bloglettersReducerDefaultState = {blogletters: [], categories: [], myBlogletters : [], featuredBlogletters : []};

export default (state = bloglettersReducerDefaultState, action) => {
  switch (action.type) {
    case 'REFRESH_DATA':
      return action.data;
    default:
      return state;
  }
};
