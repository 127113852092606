import React from "react";
import { connect } from "react-redux";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

import { startLogout } from "../actions/auth";
import { aboutRef } from "../routers/refs";

import { history } from "../routers/AppRouter";

const Header = ({ startLogout, uid, user, avatarUrl }) => {
  return (
    <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
      <Navbar.Brand as={Link} to="/">
        Blogletter
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link
            onClick={() => {
              history.push("/");
              setTimeout(() => {
                aboutRef.current &&
                  aboutRef.current.scrollIntoView({ behavior: "smooth" });
              });
            }}
          >
            O Blogletteru
          </Nav.Link>
        </Nav>
        <Nav className="ml-auto">
          {!uid && (
            <Nav.Link as={Link} to="/login">
              Přihlásit
            </Nav.Link>
          )}
          {!uid && (
            <Nav.Link as={Link} to="/register">
              Registrovat
            </Nav.Link>
          )}
          {!!uid && (
            <Navbar.Brand className="mr-0" as={Link} to="/account">
              <Image width="30" height="30" src={avatarUrl} roundedCircle />
            </Navbar.Brand>
          )}
          {!!uid && (
            <Nav.Link as={Link} to="/account">
              {user.displayName}
            </Nav.Link>
          )}
          {!!uid && <Navbar.Text className="d-none d-sm-block">|</Navbar.Text>}
          {!!uid && <Nav.Link onClick={startLogout}>Odhlásit</Nav.Link>}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout()),
});
const mapStateToProps = (state) => ({
  uid: state.auth.uid,
  user: state.auth.user,
  avatarUrl: state.auth.avatarUrl,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
