import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppRouter from "./routers/AppRouter";
import store from "./app/store";
import { Provider } from "react-redux";
import { startRefreshData } from "./actions/blogletters";
import { login, logout } from "./actions/auth";
import * as serviceWorker from "./serviceWorker";
import { firebase } from "./firebase/firebase";
import TagManager from "react-gtm-module";

import "bootstrap/dist/css/bootstrap.min.css";

TagManager.initialize({
  gtmId: "GTM-TD8WS68",
});

const jsx = (
  <React.StrictMode>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(jsx, document.getElementById("root"));

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch(login(user));
    store.dispatch(startRefreshData());
  } else {
    store.dispatch(logout());
    store.dispatch(startRefreshData());
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
