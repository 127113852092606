import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/performance";

const firebaseConfig = {
    apiKey: "AIzaSyB3lwEw1LBq0RSdO4JFSOZaNfcN9A37j9g",
    authDomain: "blogletter-77979.firebaseapp.com",
    databaseURL: "https://blogletter-77979-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "blogletter-77979",
    storageBucket: "blogletter-77979.appspot.com",
    messagingSenderId: "471198981742",
    appId: "1:471198981742:web:3bc420a5b0dc94fa43907f",
    measurementId: "G-LC0YP27JW3"
  };

firebase.initializeApp(firebaseConfig);

const database = firebase.firestore();
var googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const performance = firebase.performance(); 

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  signInSuccessUrl: '/account',
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      const user = authResult.user;
      
      if (authResult.additionalUserInfo.isNewUser && authResult.additionalUserInfo.provider === 'password') {
          user.sendEmailVerification();
      }
      
      return true;
    }
  }
};

export { uiConfig, firebase, googleAuthProvider, performance, database as default} ;