import { firebase, googleAuthProvider } from '../firebase/firebase';
import { history } from '../routers/AppRouter';

export const login = (user) => ({
    type: 'LOGIN',
    user
});

export const logout = () => ({
    type: 'LOGOUT'
});

export const startLogin = () => {
    return () => {
        return firebase.auth().signInWithPopup(googleAuthProvider);
    };
};

export const startLogout = () => {
    return () => {
        firebase.auth().signOut();
        history.push("/");
    }
}