import database from "../firebase/firebase";
import { deviceDetect } from "react-device-detect";
import { sortBlogletters, sortInCategory } from "./sorter";

export const startAddBlogletter = (blogletterData = {}) => {
  return (dispatch, getState) => {
    const {
      title = "",
      registerUrl = "",
      author = "",
      summary = "",
      category = "",
      tags = [],
      ownerEmail = "",
    } = blogletterData;
    const setOwnerEmail = getState().auth.user
      ? getState().auth.user.email
      : ownerEmail;
    const setOwnerUid = getState().auth.uid ? getState().auth.uid : null;
    const blogletter = {
      title,
      registerUrl,
      author,
      summary,
      category,
      tags,
      ownerEmail: setOwnerEmail.toLowerCase(),
      ownerUid: setOwnerUid,
      createdAt: Date.now(),
      status: "ACTIVE",
    };
    database
      .collection("blogletters")
      .add(blogletter)
      .then((ref) => {
        loadData(dispatch, getState);
      })
      .catch((e) => {
        console.log("Error adding blogletter: ", blogletter, e);
      });
    window.dataLayer.push({
      event: "blogletterAdd",
      eventProps: {
        content_type: "blogletter",
        content_name: title,
        uid: setOwnerUid,
      },
    });
  };
};

export const refreshData = (data) => ({
  type: "REFRESH_DATA",
  data,
});

const loadFeaturedBlogletters = new Promise((resolve, reject) => {
  return database
    .collection("featured-blogletters")
    .orderBy("rank")
    .get()
    .then((snapshot) => {
      const featuredBlogletters = [];
      const featuredBloglettersCache = {};
      snapshot.forEach((doc) => {
        const featured = {
          id: doc.id,
          ...doc.data(),
        };
        featuredBlogletters.push(featured);
        featuredBloglettersCache[featured.ref] = featured;
      });
      resolve({ featuredBlogletters, featuredBloglettersCache });
    })
    .catch((e) => {
      console.log("Error loading featured-blogletters", e);
    });
});

const loadCategories = new Promise((resolve, reject) => {
  return database
    .collection("categories")
    .orderBy("rank")
    .get()
    .then((snapshot) => {
      const categories = [];
      const categoriesCache = {};
      snapshot.forEach((doc) => {
        const category = {
          id: doc.id,
          ...doc.data(),
          blogletters: [],
        };
        categories.push(category);
        categoriesCache[category.id] = category;
      });
      resolve({ categories, categoriesCache });
    })
    .catch((e) => {
      console.log("Error loading categories", e);
    });
});

const loadBlogletters = (ownerEmail) =>
  new Promise((resolve, reject) => {
    return database
      .collection("blogletters")
      .where("status", "==", "ACTIVE")
      .get()
      .then((snapshot) => {
        const blogletters = [];
        const myBlogletters = [];
        snapshot.forEach((doc) => {
          const blogletter = { id: doc.id, ...doc.data() };
          if (!blogletter.tags) {
            blogletter.tags = [];
          }

          blogletters.push(blogletter);
          if (
            ownerEmail &&
            blogletter.ownerEmail === ownerEmail.toLowerCase()
          ) {
            myBlogletters.push(blogletter);
          }
        });
        myBlogletters.sort((a, b) => b.createdAt - a.createdAt);
        sortBlogletters(blogletters);
        resolve({ blogletters, myBlogletters });
      })
      .catch((e) => {
        console.log("Error loading blogletters", e);
      });
  });

const loadData = (dispatch, getState) => {
  const ownerEmail = getState().auth.user
    ? getState().auth.user.email
    : undefined;
  Promise.all([
    loadFeaturedBlogletters,
    loadCategories,
    loadBlogletters(ownerEmail),
  ]).then(([featuredData, categoryData, blogletterData]) => {
    blogletterData.blogletters.forEach((blogletter) => {
      const category = categoryData.categoriesCache[blogletter.category];
      if (!category) return;
      category.blogletters.push(blogletter);

      const featured = featuredData.featuredBloglettersCache[blogletter.id];
      if (!featured) return;
      featured.blogletter = blogletter;
    });

    categoryData.categories.forEach(sortInCategory);

    dispatch(
      refreshData({
        categories: categoryData.categories,
        blogletters: blogletterData.blogletters,
        myBlogletters: blogletterData.myBlogletters,
        featuredBlogletters: featuredData.featuredBlogletters,
      })
    );
  });
};

export const startRefreshData = () => {
  return loadData;
};

export const startEditBlogletter = (blogletterData = {}) => {
  return (dispatch, getState) => {
    const {
      title = "",
      registerUrl = "",
      author = "",
      summary = "",
      category = "",
      tags = [],
    } = blogletterData;
    const blogletter = { title, registerUrl, author, summary, category, tags };
    database
      .collection("blogletters")
      .doc(blogletterData.id)
      .update(blogletter)
      .then(() => {
        loadData(dispatch, getState);
      })
      .catch((e) => {
        console.log(
          "Error updating blogletter",
          blogletterData.id,
          blogletter,
          e
        );
      });
    window.dataLayer.push({
      event: "blogletterEdit",
      eventProps: {
        content_type: "blogletter",
        content_id: blogletterData.id,
        content_name: blogletter.title,
      },
    });
  };
};

export const startDeleteBlogletter = (id) => {
  return (dispatch, getState) => {
    database
      .collection("blogletters")
      .doc(id)
      .update({ status: "REMOVED" })
      .then(() => {
        loadData(dispatch, getState);
      })
      .catch((e) => {
        console.log("Error updating blogletter as deleted", id, e);
      });
    window.dataLayer.push({
      event: "blogletterDelete",
      eventProps: {
        content_type: "blogletter",
        content_id: id,
      },
    });
  };
};

export const startRegisterBlogletterClick = (blogletterId) => {
  return (dispatch, getState) => {
    const userId = getState().auth.uid ? getState().auth.uid : null;
    const clickRecord = {
      blogletterId,
      timestamp: new Date().toISOString(),
      userId,
      device: deviceDetect(),
    };
    window.dataLayer.push({
      event: "registration",
      eventProps: {
        content_type: "blogletter",
        content_id: blogletterId,
        uid: userId,
      },
    });
    database
      .collection("blogletter-clicks")
      .add(clickRecord)
      .catch((e) => {
        console.log("Error adding click", clickRecord, e);
      });
  };
};

export const verifyEmailAvailable = (email, callback) => {
  return database
    .collection("blogletters")
    .where("ownerEmail", "==", email)
    .get()
    .then((snapshot) => {
      callback(snapshot.size === 0);
    })
    .catch((e) => {
      console.log("Error searching for email availability", email, e);
    });
};
