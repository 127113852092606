export const sortBlogletters = blogletters => {
    blogletters.sort((a, b) => {
        const clicks = (b.clicks ? b.clicks : 0) - (a.clicks ? a.clicks : 0);
        if (clicks !== 0) {
            return clicks;
        }
        return b.createdAt - a.createdAt;
    });
}

export const sortInCategory = category => {
    if (category.blogletters.length < 6) {
        return;
    }

    const tempBlogletters = [...category.blogletters];
    tempBlogletters.sort((a, b) => {
        const createdAt = (b.createdAt ? b.createdAt : 0) - (a.createdAt ? a.createdAt : 0);
        if (createdAt !== 0) {
            return createdAt;
        }
        return b.clicks - a.clicks;
    });

    const first = tempBlogletters[0];
    const second = tempBlogletters[1];

    moveNewBlogletter(category.blogletters, first, 4);
    moveNewBlogletter(category.blogletters, second, 5);
};

const moveNewBlogletter = (blogletters, blogletter, targetPosition) => {
    const index = blogletters.indexOf(blogletter);
    if (index < 6) return;

    move(blogletters, index, targetPosition);
};

const move = (input, from, to) => { 
    const element = input.splice(from, 1)[0];
    input.splice(to, 0, element);
};