import React, { Suspense, lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Container from "react-bootstrap/Container";

import PrivateRoute from "./PrivateRoute";
import Header from "../components/Header";
import LoaderSymbol from "../components/LoaderSymbol";

import "../firebase/firebase";

const Home = lazy(() => import("../components/Home"));
const Account = lazy(() => import("../components/Account"));
const Footer = lazy(() => import("../components/Footer"));
const BlogEdit = lazy(() => import("../components/BlogEdit"));
const BlogDelete = lazy(() => import("../components/BlogDelete"));
const AddBlog = lazy(() => import("../components/AddBlog"));
const Register = lazy(() => import("../components/Register"));
const RegistrationSuccess = lazy(() =>
  import("../components/RegistrationSuccess")
);
const LoginPage = lazy(() => import("../components/LoginPage"));
const Privacy = lazy(() => import("../components/Privacy"));
const Terms = lazy(() => import("../components/Terms"));
const NotFound = lazy(() => import("../components/NotFound"));

export const history = createBrowserHistory();

function AppRouter() {
  return (
    <Router history={history}>
      <Suspense fallback={<LoaderSymbol />}>
        <div className="App min-vh-100">
          <Container fluid className="d-flex flex-column min-vh-100 pl-0 pr-0">
            <Header />
            <div className="content-holder">
              <Switch>
                <Route path="/" exact={true} component={Home} />
                <Route path="/share/:sharedBlogletter" component={Home} />
                <Route path="/login" component={LoginPage} />
                <Route path="/register" component={Register} />
                <Route
                  path="/register-success"
                  component={RegistrationSuccess}
                />
                <Route path="/privacy" component={Privacy} />
                <Route path="/terms" component={Terms} />
                <PrivateRoute path="/account/:id/edit" component={BlogEdit} />
                <PrivateRoute
                  path="/account/:id/delete"
                  component={BlogDelete}
                />
                <PrivateRoute path="/account/add" component={AddBlog} />
                <PrivateRoute path="/account" component={Account} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <Footer />
          </Container>
        </div>
      </Suspense>
    </Router>
  );
}

export default AppRouter;
